import React, {createElement} from "react"
import createReactClass from "create-react-class"
import cx from "classnames"
import assign from "object-assign"
import KeyHandler, {KEYPRESS} from "@banzai-inc/react-key-handler"
import * as shared from "auth/business/shared"
import {ContinueWithGoogle} from "shared/oauth"
import {getParameterByName} from "util/url"
import BanzaiLogo_ from "../../../images/logos/banzai-logo.svg?react"
import {Label, ErrorMessage, Select} from "../../components/inputs"
import {Button as Button_} from "../../components/buttons"
import Isvg from "react-inlinesvg"
import PlusSymbol from "../../../images/plus-symbol.svg?react"
import stateMap from "../../util/states"

export const BanzaiLogo = ({className}) => (
  <div className={cx(className, "banzai-logo h-14 inline-block mx-auto")}>
    <h1 className="sr-only">Sign Up for Banzai</h1>
    <BanzaiLogo_ />
  </div>
)

export const DoubleLogo = ({logoUrl, logoSize}) => {
  return (
    <div className="logo-header">
      <h1 className="sr-only">Sign Up for Banzai</h1>
      <div className="grid gap-x-6 grid-cols-[auto_19px_auto] justify-center items-center">
        <BanzaiLogo className="h-10" />
        <PlusSymbol />
        <Isvg src={logoUrl} className={cx("w-full", logoSize)} />
      </div>
    </div>
  )
}

export const ArrowIcon = () => <i className="bz arrow" />

// TODO: Consider moving to machine.js if necessary
export const showView = (machine, parentComponent) => {
  return machine.find((item) => item[0](parentComponent.state))[1](parentComponent)
}

const findErrorView = (errorViews, errors) => {
  if (errors) {
    const foundErrorView = errorViews.find((view) => view[0](errors))
    return foundErrorView ? foundErrorView[1] : null
  }
}

const InvalidEmailView = () => <ErrorMessage id="username">Please enter a valid email.</ErrorMessage>

const InvalidUsernameView = () => <ErrorMessage id="username">Please enter a valid username.</ErrorMessage>

const InvalidPhoneNumberView = () => <ErrorMessage id="invalid-phone-number">Please enter a valid phone number.</ErrorMessage>

const UsernameTooShort = () => <ErrorMessage id="username">Your username must be at least 5 characters long.</ErrorMessage>

const UsernameTooLong = () => <ErrorMessage id="username">Your username can be no more than 80 characters.</ErrorMessage>

const PasswordTooShortView = () => <ErrorMessage id="password">Your password must be at least 5 characters long.</ErrorMessage>

const PasswordTooLongView = () => <ErrorMessage id="password">Your password cannot be more than 128 characters.</ErrorMessage>

const InvalidPasswordView = () => <ErrorMessage id="password">Passwords can only contain letters, numbers, or symbols—no spaces, etc.</ErrorMessage>

const UsernameNotUnique = () => <ErrorMessage id="username">This username is already being used.</ErrorMessage>

const EmailNotUnique = () => <ErrorMessage id="username">This email is already being used.</ErrorMessage>

const MissingState = () => <ErrorMessage id="missing-state">Please enter your state of residence.</ErrorMessage>

const errorViews = [
  [(errors) => shared.hasError(errors, "invalid-email"), () => <InvalidEmailView />],
  [(errors) => shared.hasError(errors, "invalid-username"), () => <InvalidUsernameView />],
  [(errors) => shared.hasError(errors, "username-too-short"), () => <UsernameTooShort />],
  [(errors) => shared.hasError(errors, "username-too-long"), () => <UsernameTooLong />],
  [(errors) => shared.hasError(errors, "invalid-parent-email"), () => <InvalidEmailView />],
  [(errors) => shared.hasError(errors, "password-too-short"), () => <PasswordTooShortView />],
  [(errors) => shared.hasError(errors, "password-too-long"), () => <PasswordTooLongView />],
  [(errors) => shared.hasError(errors, "invalid-password"), () => <InvalidPasswordView />],
  [(errors) => shared.hasError(errors, "username-not-unique"), () => <UsernameNotUnique />],
  [(errors) => shared.hasError(errors, "email-not-unique"), () => <EmailNotUnique />],
  [(errors) => shared.hasError(errors, "invalid-phone-number"), () => <InvalidPhoneNumberView />],
  [(errors) => shared.hasError(errors, "missing-state"), () => <MissingState />]
]

export const ErrorView = createReactClass({
  render() {
    const {errors, wrapWithContainer} = this.props
    const errorView = findErrorView(errorViews, errors)
    if (errorView && wrapWithContainer) {
      return <ErrorMessage>{errorView ? createElement(errorView) : null}</ErrorMessage>
    } else if (errorView) {
      return errorView ? createElement(errorView) : null
    } else {
      return <div></div>
    }
  }
})

const hasError = (errors, errorKey) => errors && errors[errorKey]

export const Input = createReactClass({
  getInitialState() {
    return {
      errors: {}
    }
  },
  setError() {
    const {errors, errorKey} = this.props
    const localErrors = errors && errors[errorKey]
    this.setState({errors: localErrors})
    if (localErrors && localErrors.length > 0) {
      this.props.toggleReaction(true)
    }
  },
  onChange({target}) {
    this.props.toggleReaction(false)
    this.props.setValue(target.value)
    this.setState({errors: null})
  },
  onKeyPress(e) {
    if (this.props.onEnter && e.key === "Enter") {
      this.props.onEnter()
    }
  },
  componentDidMount() {
    if (this.props.focus) {
      this.input.focus()
    }
  },
  render() {
    const {errors} = this.state
    const {label, value, type, placeholder, onBlur, name, maxLength, customError} = this.props
    const hasCustomError = customError && customError.length > 0
    const isMessage = typeof customError === "string"
    const hasErrors = errors && Object.keys(errors).length > 0
    const errorReference = isMessage ? "error" : this.props.errorKey

    return (
      <label className="block text-left">
        <Label>{label}</Label>
        <input
          className="input"
          ref={(c) => {
            this.input = c
          }}
          onChange={this.onChange}
          value={value || undefined}
          type={type || "text"}
          placeholder={placeholder}
          aria-required="true"
          onBlur={onBlur ? onBlur.bind(null, this.setError) : this.setError}
          aria-describedby={hasCustomError || hasErrors ? errorReference : null}
          onKeyPress={this.onKeyPress}
          maxLength={maxLength}
          name={name}
        />
        {hasCustomError ? isMessage ? <ErrorMessage id="error">{customError}</ErrorMessage> : <ErrorView errors={customError} /> : null}
        {hasErrors && <ErrorView errors={errors} />}
      </label>
    )
  }
})

Input.defaultProps = {
  toggleReaction: () => null
}
// toggleReaction can make things .shake (see auth/style.css)

export const NameInput = (props) => (
  <Input
    {...assign({}, props, {
      label: "First and Last Name",
      errorKey: "name"
    })}
  />
)

export const UsernameInput = (props) => (
  <Input
    {...assign({}, props, {
      label: "Username",
      errorKey: "username",
      maxLength: 40
    })}
  />
)

export const EmailInput = (props) => (
  <Input
    {...assign({}, props, {
      label: "Email",
      errorKey: "email"
    })}
  />
)

export const ParentEmailInput = (props) => (
  <Input
    {...assign({}, props, {
      label: "Your Parent's Email",
      errorKey: "parentEmail"
    })}
  />
)

export const UsernameOrEmailInput = (props) => (
  <Input
    {...assign({}, props, {
      label: "Username or Email",
      errorKey: "email",
      showCheckmark: true
    })}
  />
)

export const PasswordInput = (props) => (
  <Input
    {...assign(
      {},
      {
        label: "Password",
        errorKey: "password",
        type: "password"
      },
      props
    )}
  />
)

export const ZipCodeInput = (props) => (
  <Input
    {...assign({}, props, {
      label: "School ZIP Code",
      errorKey: "zipCode",
      type: "number"
    })}
  />
)

export const PhoneNumberInput = (props) => {
  const {className} = props
  return (
    <Input
      {...assign({}, props, {
        className: className,
        label: "Phone Number",
        errorKey: "phoneNumber",
        placeholder: "555-555-5555"
      })}
    />
  )
}

const StateOption = ({code}) => <option value={code}>{stateMap[code]}</option>

export const StateSelect = createReactClass({
  getInitialState() {
    return {
      errors: {}
    }
  },
  setError() {
    const {errors} = this.props
    const localErrors = errors && errors["stateOfResidence"]
    this.setState({errors: localErrors})
    if (localErrors && localErrors.length > 0) {
      this.props.toggleReaction(true)
    }
  },
  onKeyPress(e) {
    if (this.props.onEnter && e.key === "Enter") {
      this.props.onEnter()
    }
  },
  componentDidMount() {
    if (this.props.focus) {
      this.input.focus()
    }
  },
  render() {
    const {className} = this.props
    return (
      <div>
        <Select label="Select State" id="state-of-residence" onChange={(e) => this.props.onChange(e.target.value)} onBlur={this.setError}>
          <option value=""></option>
          {Object.keys(stateMap).map((code) => (
            <StateOption code={code} key={code} />
          ))}
        </Select>
        <ErrorView errors={this.state.errors} />
      </div>
    )
  }
})

const languages = [
  {id: "en", language: "English"},
  {id: "es", language: "Español"}
]

export const SelectLanguage = (props) => (
  <Select label="Select Language" name="language" id="language-select" onChange={props.onChange}>
    {languages.map((l) => (
      <option key={l.id} value={l.id}>
        {l.language}
      </option>
    ))}
  </Select>
)

export const SelectSource = (props) => (
  <div className="source select">
    <select onChange={props.onChange}>
      <option key="1" value="">
        Choose session...
      </option>
      <option key="2" value="anaheim">
        American Sports Center - Anaheim, CA
      </option>
      <option key="3" value="nyc">
        Basketball City - NYC, NY
      </option>
      <option key="4" value="thousand-oaks">
        MAMBA Sports Academy - Thousand Oaks, CA
      </option>
      <option key="5" value="other">
        Other
      </option>
    </select>
    <ArrowIcon />
  </div>
)

export const AgreementInput = createReactClass({
  onChange({target}) {
    this.props.setValue(target.checked)
  },
  onClick(component) {
    this.props.setValue(component.value)
  },
  render() {
    return (
      <div className="flex gap-x-3 justify-center items-center">
        <input id="check" type="checkbox" onChange={this.onChange} aria-label="Check if you agree to terms" aria-required={true} />
        <label htmlFor="check" onClick={this.onClick}>
          I agree to the{" "}
          <a className="link-underline text-primary-1" target="_blank" href="https://help.teachbanzai.com/article/91-terms-of-use" rel="noopener noreferrer">
            terms of use
          </a>{" "}
          and{" "}
          <a className="link-underline text-primary-1" target="_blank" href={`https://${this.props.subdomain}.learnbanzai.com/privacy`} rel="noopener noreferrer">
            privacy policy
          </a>
          .
        </label>
      </div>
    )
  }
})

export const Button = ({className, onClick, disabled, children, inline, selected, pressOnEnter, variant = "primary"}) => (
  <div className={cx(inline ? "inline" : null)}>
    <KeyHandler keyEventName={KEYPRESS} keyValue="Enter" onKeyHandle={pressOnEnter && !disabled ? onClick : null} />
    <Button_
      variant={variant}
      className={cx(className, "w-full", selected && "bg-primary-1 border-primary-1 text-neutral-20 hover:bg-primary-1")}
      disabled={disabled}
      onClick={!disabled ? onClick : null}
    >
      {children}
    </Button_>
  </div>
)

export const SignupButton = (props) => <Button {...props}>{props.submitting ? "Signing Up..." : "Sign Up"}</Button>

export const SignupForm = ({children, disabled, onContinue, submitting, shake, showGoogle, userType}) => {
  const {code, birthdate} = children.props
  return (
    <div className={cx("grid gap-y-6", shake && "animation-shake")}>
      {showGoogle ? (
        <span className="grid gap-y-4">
          <ContinueWithGoogle userType={userType} klassCode={code} birthdate={birthdate} subdomain={getParameterByName("subdomain")} />
          <div className="overline-sm">OR</div>
        </span>
      ) : null}
      {children}
      <SignupButton pressOnEnter={true} onClick={onContinue} className="mt-4" disabled={disabled} submitting={submitting} />
    </div>
  )
}

export const Footer = ({children, subdomain}) => (
  <footer className="mt-8 max-w-[538px] mx-auto px-3">
    {children && (
      <div>
        <p>{children}</p>
      </div>
    )}
    <TermsAndConditions subdomain={subdomain} />
    <Copyright />
  </footer>
)

export const Screen = ({children, className}) => (
  <div className={cx("screen", className)}>
    <BanzaiLogo />
    {children}
  </div>
)

const getLogoUrl = ({fullLogo, blackLogo}) => {
  if (fullLogo) {
    return `https://static-app-logos.teachbanzai.com/full/${fullLogo}`
  } else if (blackLogo) {
    return `https://static-app-logos.teachbanzai.com/black/${blackLogo}`
  } else {
    return null
  }
}

/* Panel is replacing Screen */
export const Panel = (props) => {
  const {children, className, logoSize} = props
  const logoUrl = getLogoUrl(props)
  return (
    <div
      className={cx(
        "relative bg-neutral-20 text-neutral-2 p-4 pb-8 text-center flex flex-col justify-center md:rounded-xl md:w-[520px] md:mx-auto md:p-8 md:pb-10 lg:w-[560px]",
        className
      )}
    >
      <div className="grid gap-y-10 w-full max-w-[520px] mx-auto md:max-w-none">
        {logoUrl ? <DoubleLogo logoUrl={logoUrl} logoSize={logoSize} /> : <BanzaiLogo />}
        {children}
      </div>
    </div>
  )
}

export const Subscreen = ({children, className}) => <div className={cx(className, "grid gap-y-10")}>{children}</div>

export const TermsAndConditions = ({subdomain}) => {
  const privacy = subdomain && subdomain !== "" ? `https://${subdomain}.banzai.org/privacy` : "https://help.teachbanzai.com/article/57-privacy-policy"
  return (
    <p>
      By continuing, you agree to the&nbsp;
      <a className="link-underline" href="https://help.teachbanzai.com/article/91-terms-of-use" target="_blank">
        terms of use
      </a>
      &nbsp; and the{" "}
      <a className="link-underline" href={privacy} target="_blank">
        privacy policy
      </a>
      .
    </p>
  )
}

export const Copyright = () => <p>Copyright &#169; {new Date().getFullYear()} Banzai Inc. All rights reserved.</p>

export const Question = ({children}) => <div className="body-lg lg:text-xl font-vs-medium">{children}</div>

export const Explanation = ({children, className}) => <div className={cx("body-text-sm", className)}>{children}</div>

export const Textbox = ({children}) => <div className="grid gap-y-4">{children}</div>

export const HiddenLoginForm = createReactClass({
  componentDidUpdate() {
    if (this.props.submit) {
      this.loginForm.submit()
    }
  },
  render() {
    const {username, password, b64Data} = this.props
    const params = b64Data ? `?b64_data=${b64Data}` : ""

    return (
      <form
        className="absolute"
        method="POST"
        action={`/users/login/legacy${params}`}
        ref={(c) => {
          this.loginForm = c
        }}
      >
        <input type="hidden" name="username" value={username || ""} />
        <input type="hidden" name="password" value={password || ""} />
      </form>
    )
  }
})

export const Main = ({className, children}) => (
  <main className={cx(className, "bg-primary-1 min-h-screen text-neutral-20 text-center pb-12 md:py-12 md:flex md:flex-col md:items-center md:justify-center")}>{children}</main>
)

export const AlreadyHaveAccount = ({children}) => (
  <div className="md2:hidden">
    <a className="link-underline text-primary-1" href="/auth#/login">
      Already have an account?
    </a>
  </div>
)
