import * as qs from "query-string"

const getUrlParam = (param) => qs.parse(location.search)[param]

export const getAntiForgeryToken = () => {
  const el = document.getElementsByName("__anti-forgery-token")[0]
  return el && el.getAttribute("content")
}
export const getRefer = () => getUrlParam("refer")
export const getAdId = () => getUrlParam("adid")
