export const routeChange = routeId => (
  window.location.assign(
    window.location.pathname + window.location.search + '#/' + routeId
  )
)

// Takes an array of errors (errors) and a string (error) and returns true
// if the error is found in the array

export const hasError = (errors, error) => {
  for (let i = 0; i < errors.length; i++) {
    if (error === errors[i]) {
      return true
    }
  }
}
