import React from "react"
import createReactClass from "create-react-class"
import cx from "classnames"
import {validateDatePicker, hasDateErrors} from "auth/business/date-picker"
import * as shared from "auth/component/shared"
import range from "array-range"
import {Select} from "../../components/inputs"
import {PrimaryButton} from "../../components/buttons"

const newBirthdate = (month, day, year) => ({month, day, year})

const leadZero = (n) => (parseInt(n, 10) < 10 ? `0${n}` : n.toString())

const calculateBirthdate = (age) => {
  if (age && age > 0) {
    const today = new Date()
    return newBirthdate(leadZero(today.getMonth() + 1), leadZero(today.getDate()), (today.getFullYear() - age).toString())
  } else {
    return newBirthdate()
  }
}

export default createReactClass({
  getInitialState() {
    return newBirthdate()
  },
  handleAgeChange({target}) {
    this.setState(calculateBirthdate(target.value))
  },
  onContinue(onContinueFn) {
    onContinueFn(`${this.state.year}-${this.state.month}-${this.state.day}`)
  },
  hasErrors() {
    return hasDateErrors(validateDatePicker(this.state))
  },
  render() {
    const hasErrors = this.hasErrors()
    return (
      <shared.Subscreen>
        <shared.Textbox>
          <shared.Question>How old are you?</shared.Question>
          <shared.Explanation>Providing your age helps you get the right experience with Banzai and protects your privacy.</shared.Explanation>
        </shared.Textbox>
        <div className="grid gap-y-10">
          <Select hideLabel={true} label="Select Your Age" onChange={this.handleAgeChange}>
            <option value="">Select your age...</option>
            {range(5, 101).map((v) => (
              <option key={v}>{v}</option>
            ))}
          </Select>
          <PrimaryButton 
            onClick={!hasErrors ? this.onContinue.bind(null, this.props.onContinue) : null} 
            className={cx("continue-button")} 
            disabled={hasErrors}>
              Continue
          </PrimaryButton>
        </div>
      </shared.Subscreen>
    )
  }
})
