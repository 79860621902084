import React from "react"
import createReactClass from "create-react-class"
import cx from "classnames"
import {validateAdultDirectSignupForm, anyErrors, isEmailValid} from "shared/validate"
import assign from "object-assign"
import * as shared from "auth/component/shared"
import {validateUniqueUsername} from "auth/client"
import {getParameterByName} from "util/url"

const withSignup = (Component) =>
  createReactClass({
    getInitialState() {
      return {
        isUsernameUnique: null,
        name: null,
        email: null,
        password: null,
        language: null,
        agree: null,
        subdomain: getParameterByName("subdomain") // needed on state to check for "NBPA" sponsor in error tests
      }
    },
    setName(name) {
      this.setState({name: name.trim()})
    },
    setEmail(email) {
      this.setState({email: email.trim(), isUsernameUnique: null})
    },
    setPassword(password) {
      this.setState({password})
    },
    setLanguage(e) {
      this.setState({language: e.target.value})
    },
    setAgree(agree) {
      this.setState({agree})
    },
    onContinue(f) {
      f(this.state, (newState) => {
        this.setState(newState)
      })
    },
    checkUniqueUsername(setError) {
      const {email} = this.state
      if (isEmailValid(email)) {
        validateUniqueUsername(email, (err, resp) => {
          if (err) {
            throw new Error("Unknown error on validate username [" + email + "]: " + err)
          }
          this.setState({isUsernameUnique: resp.body})
        })
      }
      setError()
    },
    render() {
      const {onContinue, submitting, phoneNumber, stateOfResidence, turnOffGoogleSSO, collectPhone, collectState} = this.props

      const errors = validateAdultDirectSignupForm(assign(this.state, {phoneNumber, stateOfResidence}), {collectPhone, collectState})

      return (
        <shared.SignupForm
          disabled={anyErrors(errors) || this.props.submitting}
          onContinue={this.onContinue.bind(null, onContinue)}
          submitting={submitting}
          showGoogle={this.state.subdomain === "nbpagrassroots" || turnOffGoogleSSO ? false : true}
          userType="Direct"
        >
          <Component
            {...assign({}, this.props, {
              setLanguage: this.setLanguage,
              setPassword: this.setPassword,
              setName: this.setName,
              setEmail: this.setEmail,
              checkUniqueUsername: this.checkUniqueUsername,
              setAgree: this.setAgree,
              errors: errors,
              subdomain: this.state.subdomain
            })}
          />
        </shared.SignupForm>
      )
    }
  })

/* Removed choose session select (not needed this year for NBPA)
{subdomain === "nbpagrassroots" ? <shared.SelectSource onChange={setSource} /> : null}
*/

const Over13_ = ({
  setName,
  setEmail,
  checkUniqueUsername,
  errors,
  setPassword,
  setLanguage,
  setStateOfResidence,
  setPhoneNumber,
  setAgree,
  subdomain,
  collectPhone,
  collectState
}) => {
  const both = collectPhone && collectState
  return (
    <div className="grid gap-y-6">
      <shared.NameInput setValue={setName} />
      <shared.EmailInput customError={errors.isUsernameUnique} errors={errors} setValue={setEmail} onBlur={checkUniqueUsername} />
      <shared.PasswordInput errors={errors} setValue={setPassword} />
     {(collectPhone || collectState) && <div className={cx(both && "grid-cols-2", "grid gap-x-3")}>
        {collectPhone && <shared.PhoneNumberInput errors={errors} setValue={setPhoneNumber} />}
        {collectState && <shared.StateSelect errors={errors} onChange={setStateOfResidence} />}
      </div>}
      <shared.SelectLanguage onChange={setLanguage} />
      <shared.AgreementInput setValue={setAgree} subdomain={subdomain} />
    </div>
  )
}

export default withSignup(Over13_)
