import React from "react"
import createReactClass from "create-react-class"
import DocumentTitle from "react-document-title"
import KeyHandler from "@banzai-inc/react-key-handler"
import * as shared from "auth/component/shared"
import {Input} from "./body/placeholder"
import onClickOutside from "react-onclickoutside"
import * as Client from "./body/client"
import cx from "classnames"
import * as str from "shared/string"
import {stop} from "shared/event"
import {Label} from "../../../../components/inputs"
import {PrimaryButton} from "../../../../components/buttons"
import Exclamation from "../../../../../images/exclamation-icon.svg?react"
import Eyes from "../../../../../images/eyes-looking-right.svg?react"

const zipReady = (zip) => {
  return /^\d{5}(?:[ -]\d{4})?|[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(zip)
}

const conditionZip = (zip) => {
  switch (zip.length) {
    // USA case
    case 10:
    case 5:
      return zip.substring(0, 5)

    // Canada case
    case 6:
      zip = zip.substring(0, 3) + " " + zip.substring(3)
    case 7:
      return zip.replace("-", " ")

    // Shouldn't pass zipReady, just return it
    default:
      return zip
  }
}

const SchoolItem = ({school, onSelectSchool, isHighlighted}) => {
  const name = school["Name"]
  return (
    <>
      <KeyHandler keyEventName="keypress" keyValue="Enter" onKeyHandle={isHighlighted ? onSelectSchool : null} />
      <li
        id={str.toKebab(name)}
        className={cx("overline-sm p-4 hover:bg-primary-1 hover:text-neutral-20", isHighlighted && "bg-primary-1 text-neutral-20")}
        role="button"
        onClick={onSelectSchool}
      >
        {name}
      </li>
    </>
  )
}

const sortBy = (schools) =>
  schools.sort((a, b) => {
    if (a["Name"] < b["Name"]) {
      return -1
    } else if (a["Name"] > b["Name"]) {
      return 1
    } else {
      return 0
    }
  })

const getActivedescendant = (schools, idx) => str.toKebab(schools[idx].Name)

const schoolsContainerCls = "absolute inset-x-0 bg-neutral-19 text-sm rounded-xl text-left"

const Container = ({children}) => <div className="absolute inset-x-0 bg-neutral-19 text-sm rounded-xl text-left">{children}</div>

const Schools = onClickOutside(
  createReactClass({
    getInitialState() {
      return {highlightedIndex: 0}
    },
    handleClickOutside() {
      this.props.onResetZip()
    },
    onManualSchool(e) {
      this.props.onManualSchool()
      stop(e)
    },
    setHighlightedIndex(idx) {
      this.setState({highlightedIndex: idx})
    },
    onDownArrow(count) {
      const index = this.state.highlightedIndex
      if (index >= count - 1) {
        this.setHighlightedIndex(0)
      } else {
        this.setHighlightedIndex(index + 1)
      }
    },
    onUpArrow(count) {
      const index = this.state.highlightedIndex
      if (index === 0) {
        this.setHighlightedIndex(count - 1)
      } else {
        this.setHighlightedIndex(index - 1)
      }
    },
    render() {
      const highlightedIndex = this.state.highlightedIndex
      const {schools, onSelectSchool} = this.props
      const sortedSchools = sortBy(schools, (s) => s["Name"])
      const count = sortedSchools.length
      const items = sortedSchools.map((s, idx) => {
        return <SchoolItem school={s} key={s["Id"]} isHighlighted={idx === highlightedIndex} onSelectSchool={onSelectSchool.bind(null, s)} />
      })
      return (
        <Container>
          <p className="text-sm py-3 px-4" id="select-school-list">
            Select your school from the list:
          </p>
          <KeyHandler keyEventName="keyup" keyValue={"ArrowDown" || "Down"} onKeyHandle={() => this.onDownArrow(count)} />
          <KeyHandler keyEventName="keyup" keyValue={"ArrowUp" || "Up"} onKeyHandle={() => this.onUpArrow(count)} />
          <ul
            id="school-list"
            className="bg-neutral-17 text-left max-h-[246px] overflow-auto"
            tabIndex="0"
            aria-labelledby="select-school-list"
            role="listbox"
            aria-activedescendant={getActivedescendant(sortedSchools, highlightedIndex)}
          >
            {items}
          </ul>
          <div className="py-3 px-4" onClick={this.onManualSchool}>
            <a className="link text-sm" href="#">
              Click here if you can't find your school.
            </a>
          </div>
        </Container>
      )
    }
  })
)

const SelectedSchool = createReactClass({
  _onClick(e) {
    this.props._onClick()
    stop(e)
  },
  render() {
    const school = this.props.school
    return (
      <div className="mt-10">
        <div className="overline-sm">
          <span>{school["Name"]}</span>
          <a className="link ml-2" href="#" onClick={this._onClick}>
            (undo)
          </a>
        </div>
      </div>
    )
  }
})

const EmptyView = ({children}) => <div className="p-4 flex flex-col text-center gap-y-3 min-h-[116px]">{children}</div>

const NoSchools = createReactClass({
  _onClick(e) {
    this.props.onManualSchool()
    stop(e)
  },
  render() {
    return (
      <Container>
        <EmptyView>
          <div className="w-6 h-6 mx-auto">
            <Exclamation />
          </div>
          <div className="text-sm">
            We failed to find a school in that ZIP. Try another one, or{" "}
            <a className="link" href="#" onClick={this._onClick}>
              type your own school name
            </a>
            .
          </div>
        </EmptyView>
      </Container>
    )
  }
})

const LoadingSchools = createReactClass({
  render() {
    return (
      <Container>
        <EmptyView>
          <div className="w-6 h-6 mx-auto">
            <Eyes />
          </div>
          <span className="text-sm">Finding schools in your area...</span>
        </EmptyView>
      </Container>
    )
  }
})

const ManualSchool = ({onSchoolNameChange}) => (
  <div className="mt-10">
    <label>
      <Label>Type your school's name</Label>
      <Input type="text" name="school_name" onChange={onSchoolNameChange} />
    </label>
  </div>
)

const School = createReactClass({
  render() {
    let view
    // Weirdly, destructuring school and manual from this.props doesn't work!
    const school = this.props.school
    const manual = this.props.manualSchool
    const {onSchoolNameChange, onTryAgain, onResetZip, onSelectSchool, onManualSchool} = this.props
    if (manual) {
      view = <ManualSchool onSchoolNameChange={onSchoolNameChange} />
    } else if (school) {
      view = <SelectedSchool school={school} _onClick={onTryAgain} />
    } else {
      const schools = this.props.schools /* test with an array of school objects */
      if (!schools) {
        view = <LoadingSchools />
      } else if (schools.length > 0) {
        view = <Schools schools={schools} onResetZip={onResetZip} onSelectSchool={onSelectSchool} onManualSchool={onManualSchool} />
      } else {
        view = <NoSchools onManualSchool={onManualSchool} />
      }
    }
    return view
  }
})

export default createReactClass({
  defaultState: {
    zip: "",
    schoolNameValidated: false,
    schoolName: "",
    schools: null,
    loadingSchools: false,
    school: null,
    manualSchool: false,
    errors: {}
  },
  getInitialState() {
    return this.defaultState
  },
  onResetZip() {
    this.setState({
      schoolNameValidated: false,
      schoolName: "",
      zip: "",
      schools: null,
      loadingSchools: false,
      school: null,
      manualSchool: false
    })
  },
  isValid() {
    const {school, schoolNameValidated, zip} = this.state
    return school || (schoolNameValidated && zipReady(zip))
  },
  hideButton() {
    // Prevents the finish button from appearing over the top of the schools.
    const {schools} = this.state
    return schools && schools.length > 0 && !this.isValid()
  },
  onZipChange(e) {
    var zip = e.target.value.toUpperCase()
    if (zipReady(zip)) {
      zip = conditionZip(e.target.value)
      if (!this.state.loadingSchools) {
        this.setState({zip: zip, loadingSchools: true})
        const self = this
        Client.searchSchools(zip, (err, res) => {
          if (err) {
            throw new Error("Unknown error when changing zip: " + err)
          } else if (res) {
            self.setState({schools: res.body || []})
          }
        })
      }
    } else {
      this.setState({
        zip: zip,
        loadingSchools: false,
        schools: null,
        school: null,
        schoolNameValidated: false,
        schoolName: "",
        manualSchool: false
      })
    }
  },
  onSelectSchool(school) {
    this.setState({school: school})
  },
  onManualSchool() {
    this.setState({
      school: null,
      schools: null,
      manualSchool: true
    })
  },
  onTryAgain() {
    this.setState({school: null})
  },
  onSchoolNameChange(e) {
    const name = e.target.value
    this.setState({
      schoolName: name,
      schoolNameValidated: name !== ""
    })
  },
  onSubmit(e) {
    if (!this.isValid()) {
      stop(e)
    }
  },
  render() {
    const {zip, school, schools, manualSchool} = this.state
    let schoolView
    if (zipReady(zip)) {
      schoolView = (
        <School
          school={school}
          schools={schools}
          manualSchool={manualSchool}
          onManualSchool={this.onManualSchool}
          onSelectSchool={this.onSelectSchool}
          onSchoolNameChange={this.onSchoolNameChange}
          onResetZip={this.onResetZip}
          onTryAgain={this.onTryAgain}
        />
      )
    }
    return (
      <DocumentTitle title="Select School: Banzai">
        <shared.Main>
          <shared.Panel className="h-[76vh] !justify-normal">
            <div className="grid gap-y-12">
              <shared.Textbox>
                <shared.Question>Help us find your school.</shared.Question>
                <shared.Explanation>Complete the signup process by providing the ZIP code of the school you teach at.</shared.Explanation>
              </shared.Textbox>
              <form method="POST" action="/teacher/select/school" onSubmit={this.onSubmit}>
                <div className="relative">
                  <label>
                    <Label>School Zip Code</Label>
                    <Input type="text" maxLength={10} name="zip" className="zip" onChange={this.onZipChange} value={zip} />
                  </label>
                  <input type="hidden" name="school_id" value={(school || {})["Id"] || ""} />
                  {schoolView}
                </div>
                <div className={cx("mt-12", this.hideButton() && "hide")}>
                  <PrimaryButton className="w-full" type="submit" disabled={!this.isValid()}>
                    Continue
                  </PrimaryButton>
                  {/* <input type="submit" value="Continue" disabled={!this.isValid()} /> */}
                </div>
              </form>
            </div>
          </shared.Panel>
        </shared.Main>
      </DocumentTitle>
    )
  }
})
