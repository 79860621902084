import React from "react"
import createReactClass from "create-react-class"
import range from "range-function"
import * as event from "shared/event"
import * as client from "auth/client"
import Spinner from "../../images/spinner-2.svg?react"

const codeLen = 6

export const codePattern = new RegExp(/^[a-zA-Z0-9]+$/i)

const codeAsArray = (codeAsString) => codeAsString.split("")

const codeAsString = (array) => array.join("")

const isCodeComplete = (array) => codeAsString(array).length === codeLen

/* klass key can have the following values:
 *  null: Code has not been checked with server.
 *  fetching: Fetching a klass from the server based on the code.
 *  {...}: Klass was found on the server and an object was returned.
 *  "not-found": No klass with that code was found.
 */

const defaultCodeState = () => ({
  code: [null, null, null, null, null, null],
  klass: null
})

const characterPositionTextArr = ["first", "second", "third", "fourth", "fifth", "sixth"]

const Codeput = createReactClass({
  select() {
    this.text.select()
  },
  onFocus() {
    this.select()
  },
  onChange(e) {
    const {onChange, pos} = this.props
    let val = e.target.value
    if (codePattern.test(val)) {
      val = val.toUpperCase()
      onChange(pos, val)
    } else {
      onChange(pos, "")
    }
  },
  onKeyDown(e) {
    const {onBackspace, onMoveLeft, onMoveRight, pos} = this.props
    if (this.text.value === "" && (e.key === "Delete" || e.key === "Backspace")) {
      onBackspace(pos)
    }
    if (e.key === "ArrowRight") {
      onMoveRight(pos)
    }
    if (e.key === "ArrowLeft") {
      onMoveLeft(pos)
    }
  },
  render() {
    const {pos, value, onPaste} = this.props
    return (
      <label>
        <span className="sr-only">{`Enter the ${characterPositionTextArr[pos]} character of the class code.`}</span>
        <input
          className="border-2 border-neutral-17 rounded-md font-main font-vs-bold text-sm w-10 h-[42px] text-center"
          type="text"
          ref={(c) => {
            this.text = c
          }}
          value={value || ""}
          onFocus={this.onFocus}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          onClick={this.select}
          onPaste={onPaste}
        />
      </label>
    )
  }
})

const FoundKlass = ({children}) => <div className="body-text-sm">{children}</div>

/* eslint-disable no-script-url */
const TryAgain = ({children, onClick}) => (
  <div>
    <a className="link-underline text-primary-1" href="#" onClick={onClick}>
      {children}
    </a>
  </div>
)
/* eslint-enable no-script-url */

const customErrorMessage = (klass, errors = [[() => true, () => null]]) => (errors.find(([test]) => test(klass)) || [, () => null])[1](klass) // eslint-disable-line

const setStateWithCode = (code) => ({code: code.split(""), klass: null})

/**
 * @param {string} codeFromParams
 * @param {boolean} waiting
 * @param {Function} onSetCode
 * @param {Function} onFetchedKlass
 * @param {Function} onCancel
 * @param {Array} customErrors
 * @returns {JSX.Element}
 */

export default createReactClass({
  getInitialState() {
    const code = this.props.codeFromParams
    return code ? setStateWithCode(code) : defaultCodeState()
  },
  focusCodeput(pos) {
    const nextCodeput = this["codeput-" + pos]
    if (nextCodeput) {
      nextCodeput.select()
    } else {
      const currentCodeput = this["codeput-" + (pos - 1)]
      if (currentCodeput) {
        currentCodeput.text.blur()
      }
    }
  },
  completeCode() {
    let {code} = this.state
    const {onSetCode, onFetchedKlass} = this.props
    const complete = isCodeComplete(code)
    this.setState({klass: null})
    if (complete) {
      code = codeAsString(code)
      onSetCode(code)
      this.setState({klass: "fetching"})
      client.getKlassByCode(code, (err, resp) => {
        if (err) {
          throw new Error("Unexpected error fetching klass code on signup: " + err)
        } else {
          const klass = resp.body
          this.setState({klass: klass ? klass : "not-found"})
          if (!customErrorMessage(klass, this.props.customErrors)) {
            onFetchedKlass(klass)
          }
        }
      })
    }
  },
  onChangeCodeput(pos, val) {
    const {code} = this.state
    const v = val || null
    code[pos] = v
    this.setState({code})
    if (v) {
      this.focusCodeput(pos + 1)
    }
    this.completeCode()
  },
  onBackspace(pos) {
    this.focusCodeput(pos - 1)
  },
  componentDidMount() {
    if (this.state.code) {
      this.completeCode()
    } else {
      this.focusCodeput(0)
    }
  },
  onCancel(e) {
    e.preventDefault()
    this.setState(defaultCodeState())
    this.props.onCancel()
    this.focusCodeput(0)
  },
  onPaste(e) {
    const pasted = e.clipboardData.getData("Text").trim()
    if (pasted.length === codeLen && codePattern.test(pasted)) {
      this.setState({code: codeAsArray(pasted)}, () => {
        this.completeCode()
        this.focusCodeput(5)
      })
      event.stop(e)
    }
  },
  /* eslint-disable */
  onMoveRight(pos) {
    // this.focusCodeput(pos + 1) // Fails to select the text
  },
  onMoveLeft(pos) {
    // this.focusCodeput(pos - 1)
  },
  /* eslint-enable */
  render() {
    const {klass} = this.state
    const {waiting, customErrors, params} = this.props
    const spinnerView = waiting && <Spinner className="spinner" />
    let klassView = null
    if (klass) {
      if (typeof klass === "object") {
        const message = customErrorMessage(klass, customErrors)
        if (message) {
          klassView = (
            <FoundKlass>
              {message}
              <TryAgain onClick={this.onCancel}>Try again.</TryAgain>
            </FoundKlass>
          )
        } else {
          klassView = (
            <FoundKlass>
              You&apos;re joining <strong>{klass.name}</strong>.<TryAgain onClick={this.onCancel}>Undo.</TryAgain>
            </FoundKlass>
          )
        }
      } else {
        if (klass === "fetching") {
          klassView = <FoundKlass>Finding your class...</FoundKlass>
        } else {
          klassView = (
            <FoundKlass>
              Sorry, we couldn&apos;t find that class code.
              <TryAgain onClick={this.onCancel}>Try again.</TryAgain>
            </FoundKlass>
          )
        }
      }
    }
    return (
      <div className="grid gap-y-10">
        <div className="flex grid-cols-6 gap-x-3 justify-center">
          {range(6).map((i) => (
            <Codeput
              pos={i}
              ref={(c) => {
                this["codeput-" + i] = c
              }}
              key={i}
              value={this.state.code[i]}
              onBackspace={this.onBackspace}
              onChange={this.onChangeCodeput}
              onPaste={this.onPaste}
              onMoveLeft={this.onMoveLeft}
              onMoveRight={this.onMoveRight}
            />
          ))}
        </div>
        <div className="min-h-[4.5rem]">
          {spinnerView}
          {klassView}
        </div>
      </div>
    )
  }
})
