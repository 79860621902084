import * as R from "react"
import ReactDOM from "react-dom"
import createReactClass from "create-react-class"
const isPlaceholderSupported = (typeof document !== 'undefined') && 'placeholder' in document.createElement('input')
import objectAssign from "object-assign"

/**
 * NOTE: only supports "controlled" inputs (http://facebook.github.io/react/docs/forms.html#controlled-components)
 */
const createShimmedElement = function(React, elementConstructor, name) {
    return createReactClass({
        displayName: name,

        UNSAFE_componentWillMount: function() {
            this.needsPlaceholding = this.props.placeholder && !isPlaceholderSupported
        },
        UNSAFE_componentWillReceiveProps: function(props) {
            this.needsPlaceholding = props.placeholder && !isPlaceholderSupported
        },

        // this component supports valueLink or value/onChange.
        // borrowed from LinkedValueMixin.js
        getValue: function() {
            if (this.props.valueLink) {
                return this.props.valueLink.value
            }

            return this.props.value
        },

        getOnChange: function() {
            if (this.props.valueLink) {
                return this._handleLinkedValueChange
            }

            return this.props.onChange
        },

        _handleLinkedValueChange: function(e) {
            this.props.valueLink.requestChange(e.target.value)
        },

        // keep track of focus
        onFocus: function(e) {
            this.hasFocus = true
            this.setSelectionIfNeeded(e.target)

            if (this.props.onFocus) {
                return this.props.onFocus(e)
            }
        },
        onBlur: function(e) {
            this.hasFocus = false

            if (this.props.onBlur) {
                return this.props.onBlur(e)
            }
        },

        setSelectionIfNeeded: function(node) {
            // if placeholder is visible, ensure cursor is at start of input
            if (this.needsPlaceholding &&
                    this.hasFocus &&
                    this.isPlaceholding &&
                    (node.selectionStart !== 0 || node.selectionEnd !== 0)) {
                node.setSelectionRange(0, 0)

                return true
            }

            return false
        },

        onChange: function(e) {
            const onChange = this.getOnChange()
            let value
            let index

            if (this.isPlaceholding) {
                // remove placeholder when text is added
                value = e.target.value
                index = value.indexOf(this.props.placeholder)

                if (index !== -1) {
                    e.target.value = value.slice(0, index)
                }
            }

            if (onChange) {
                return onChange(e)
            }
        },

        onSelect: function(e) {
            if (this.isPlaceholding) {
                this.setSelectionIfNeeded(e.target)

                return false
            } else if (this.props.onSelect) {
                return this.props.onSelect(e)
            }
        },

        componentDidUpdate: function() {
            this.setSelectionIfNeeded(ReactDOM.findDOMNode(this))
        },

        render: function() {
            let value
            let additionalProps = {}

            if (this.needsPlaceholding) {
              additionalProps = {
                onFocus: this.onFocus,
                onBlur: this.onBlur,
                onChange: this.onChange,
                onSelect: this.onSelect,
                valueLink: undefined
              }

              value = this.getValue()

              if (!value) {
                this.isPlaceholding = true
                value = this.props.placeholder
                additionalProps.type = "text"
                additionalProps.className += ' placeholder'
              } else {
                this.isPlaceholding = false
              }

              additionalProps.value = value
            }
            return React.createElement(elementConstructor, objectAssign({}, this.props, additionalProps, {className: "input"}), this.props.children)
        }
    })
}

export const Input = createShimmedElement(R, 'input', 'Input')
export const Textarea = createShimmedElement(R, 'textarea', 'Textarea')
