import React from "react"
import createReactClass from "create-react-class";
import {
  validateChildDirectSignupForm,
  anyErrors
} from "shared/validate"
import assign from "object-assign"
import * as shared from "auth/component/shared"
import {validateUniqueUsername} from "auth/client"
import {getParameterByName} from "util/url"

const withSignup = (Component) => (
  createReactClass({
    getInitialState() {
      return {
        isUsernameUnique: null,
        username: null,
        parentEmail: null,
        password: null,
        language: null,
        agree: null,
        subdomain: getParameterByName("subdomain") // needed on state to check for "NBPA" sponsor in error tests
      }
    },
    setUsername(username) {
      this.setState({username: username.trim(), isUsernameUnique: null})
    },
    setParentEmail(parentEmail) {
      this.setState({parentEmail: parentEmail.trim()})
    },
    setPassword(password) {
      this.setState({password})
    },
    setLanguage(e) {
      this.setState({language: e.target.value})
    },
    setAgree(agree) {
      this.setState({agree})
    },
    onContinue(f) {
      f(this.state, newState => {
        this.setState(newState)
      })
    },
    checkUniqueUsername(setError) {
      const {username} = this.state
      if (username) {
        validateUniqueUsername(username, (err, resp) => {
          if (err) {
            throw new Error("Unknown error on validate username [" + username + "]: " + err)
          }
          this.setState({isUsernameUnique: resp.body})
        })
      }
      setError()
    },
    render() {
      const errors = validateChildDirectSignupForm(this.state)
      const {onContinue, submitting} = this.props
      return (
        <shared.SignupForm
          disabled={anyErrors(errors) || submitting}
          onContinue={this.onContinue.bind(null, onContinue)}
          submitting={submitting}
          showGoogle={false}
          userType="Direct">
          <Component {...assign({}, this.props, {
            setLanguage: this.setLanguage,
            setPassword: this.setPassword,
            setUsername: this.setUsername,
            setParentEmail: this.setParentEmail,
            checkUniqueUsername: this.checkUniqueUsername,
            setAgree: this.setAgree,
            errors: errors,
            subdomain: this.state.subdomain
          })} />
        </shared.SignupForm>
      )
    }
  })
)

/* Removed choose session select (not needed this year for NBPA)
{subdomain === "nbpagrassroots" ? <shared.SelectSource onChange={setSource} /> : null}
*/

const Under13_ = ({setUsername, setParentEmail, checkUniqueUsername, errors, setPassword, setLanguage, setAgree, subdomain}) => (
  <div className="grid gap-y-6">
    <shared.UsernameInput
      customError={errors.isUsernameUnique}
      errors={errors}
      setValue={setUsername}
      onBlur={checkUniqueUsername}
    />
    <shared.ParentEmailInput setValue={setParentEmail} errors={errors} />
    <shared.PasswordInput
      errors={errors}
      setValue={setPassword}
    />
    <shared.SelectLanguage onChange={setLanguage} />
    <shared.AgreementInput setValue={setAgree} subdomain={subdomain} />
  </div>
)

export default withSignup(Under13_, validateChildDirectSignupForm)
