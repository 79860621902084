import React from "react"
import * as shared from "auth/component/shared"
import {validateLoginForm, loginHasErrors} from "shared/validate"
import DocumentTitle from "react-document-title"
import * as url from "util/url"
import {ContinueWithGoogle, ContinueWithClever} from "shared/oauth"
import {TextButton2} from "../../components/buttons"

const defaultUsernameError = "Oops, we can't find that username and password."
const legacyClassError = "You are logging in to an account that belongs to a legacy class which has been locked. Talk to your teacher, or sign up with a new account."
const googleLoginError = "Oops, that email is not signed up using Google."

const errors = {
  username: defaultUsernameError,
  legacy: legacyClassError,
  google: googleLoginError
}

const getErrorMessage = (error) => errors[error]

const getSignupLink = () => {
  const subdomain = url.getParameterByName("subdomain")
  return url.getParameterByName("signup") === "direct" && subdomain ? `/auth#direct/signup?subdomain=${subdomain}` : "/signup"
}

export default class extends React.Component {
  constructor(props) {
    super(props)

    this.setUsernameOrEmail = this.setUsernameOrEmail.bind(this)
    this.setPassword = this.setPassword.bind(this)
    this.onClick = this.onClick.bind(this)
    this.onClickForgot = this.onClickForgot.bind(this)

    this.state = {
      usernameOrEmail: null,
      password: null,
      usernameError: getErrorMessage(url.getParameterByName("error"))
    }
  }

  setUsernameOrEmail(usernameOrEmail) {
    this.setState({
      usernameOrEmail: usernameOrEmail,
      usernameError: null
    })
  }

  setPassword(password) {
    this.setState({
      password: password,
      usernameError: null
    })
  }

  onClick() {
    this.form.submit()
  }

  onClickForgot() {
    document.location.href = "/id#/courtyard/id/password/forgot"
  }

  render() {
    const state = this.state
    const subdomain = url.getParameterByName("subdomain")
    const b64_data = url.getParameterByName("b64_data")
    const {usernameError} = state
    const localErrors = validateLoginForm(state)
    const exists = (string) => string && string != "" && string != "null"
    const paramString = exists(b64_data) ? "?b64_data=" + b64_data : ""
    const postUri = "/users/login/legacy" + paramString
    const hideClever = !!url.getParameterByName("hide_clever")

    return (
      <DocumentTitle title="Log In: Banzai">
        <shared.Main>
          <shared.Panel className="banzai-login">
            <div className="grid gap-y-6">
              <form
                method="POST"
                action={postUri}
                ref={(c) => {
                  this.form = c
                }}
              >
                <div className="grid gap-y-6">
                  <shared.UsernameOrEmailInput setValue={this.setUsernameOrEmail} name="username" focus={true} customError={usernameError} />

                  <shared.PasswordInput
                    className="password"
                    setValue={this.setPassword}
                    name="password"
                    onEnter={() => {
                      if (!loginHasErrors(localErrors)) {
                        this.onClick()
                      }
                    }}
                  />
                  <div className="forgot-container">
                    <TextButton2 href="https://banzai.org/id#/courtyard/id/password/forgot">Forgot Password?</TextButton2>
                  </div>
                  <input type="hidden" name="signup" value={url.getParameterByName("signup") || ""} />
                  <input type="hidden" name="subdomain" value={url.getParameterByName("subdomain") || ""} />
                  <div className="max-w-[400px] mx-auto w-full">
                    <div className="">
                      <shared.Button
                        className="log-in "
                        pressOnEnter={true}
                        disabled={loginHasErrors(localErrors) && "disabled"}
                        onClick={!loginHasErrors(localErrors) ? this.onClick : null}
                      >
                        Log In
                      </shared.Button>
                    </div>
                  </div>
                </div>
              </form>
              <div className="overline-sm">OR</div>
              {subdomain === "nbpagrassroots" ? null : <ContinueWithGoogle paramString={paramString} actionType="login" />}
              {hideClever ? null : <ContinueWithClever actionType="login" />}
            </div>
            <div>
              <TextButton2 href={getSignupLink()}>Sign Up</TextButton2>
            </div>
          </shared.Panel>
          <shared.Footer subdomain={subdomain} />
        </shared.Main>
      </DocumentTitle>
    )
  }
}
