import React from "react"
import DocumentTitle from "react-document-title"
import createReactClass from "create-react-class"
import * as shared from "auth/component/shared"
import DateOfBirthPrompt from "auth/component/date-picker"
import Under13Signup from "auth/component/direct/under-13-signup"
import Over13Signup from "auth/component/direct/over-13-signup"
import * as client from "auth/client"
import {getAge} from "auth/business/date-picker"
import assign from "object-assign"
import * as bizShared from "auth/business/shared"
import {TextButton2} from "../../components/buttons"
import cx from "classnames"

const transformBirthdate = (birthdate) => {
  const b = birthdate.split("-")
  return b[2] + "-" + b[1] + "-" + b[0]
}

const machine = [
  [({birthdate}) => !birthdate, ({setBirthdate}) => <DateOfBirthPrompt key="dob" onContinue={setBirthdate} />],
  // Under13Signup and Over13Signup could benefit from a HOC
  // like was done in student/signup.js
  [({birthdate}) => birthdate && getAge(birthdate) < 13, ({setSignupState, state}) => <Under13Signup key="under-13" onContinue={setSignupState} submitting={state.submitting} />],
  [
    ({birthdate}) => birthdate && getAge(birthdate) >= 13,
    ({setSignupState, setPhoneNumber, setStateOfResidence, state}) => (
      <Over13Signup
        key="over-13"
        onContinue={setSignupState}
        birthdate={transformBirthdate(state.birthdate)}
        setPhoneNumber={setPhoneNumber}
        setStateOfResidence={setStateOfResidence}
        {...state}
      />
      // TODO: Birthdate needed here to send to server for google oauth. Should be included in the declaration
      // of this component, but it has no knowledge of top level state, where birthdate is held.
    )
  ]
]

const transformBody = (subdomain = "", {birthdate, name, username, parentEmail, email, password, language, source, stateOfResidence, phoneNumber}) => {
  return {
    user: {
      birthdate: transformBirthdate(birthdate),
      name: name,
      username: username || email,
      parent_email: parentEmail,
      password: password,
      language: language,
      direct_signup_source: source,
      state_code: stateOfResidence,
      phone_number: phoneNumber
    },
    subdomain
  }
}

export default createReactClass({
  getInitialState() {
    return {
      birthdate: null,
      name: null,
      username: null,
      parentEmail: null,
      email: null,
      password: null,
      language: null,
      source: null,
      submitting: false,
      submit: false,
      stateOfResidence: null,
      phoneNumber: null
    }
  },
  setBirthdate(birthdate) {
    this.setState({birthdate})
  },
  setStateOfResidence(stateOfResidence) {
    this.setState({stateOfResidence})
  },
  setPhoneNumber(rawPhoneNumber) {
    const phoneNumber = rawPhoneNumber.replace(/[^0-9]/g, "")
    this.setState({phoneNumber, rawPhoneNumber})
  },
  setSignupState(newValues, signalFinished) {
    this.setState(assign({}, newValues, {submitting: true}), () => {
      const state = this.state
      client.completeDirectSignup(transformBody(this.props.params.subdomain, state), (err, resp) => {
        if (err) {
          throw new Error("Unknown error on completing direct signup: " + err)
        } else if (resp) {
          switch (resp.status) {
            case 200:
              this.setState({submit: true})
              break
            case 422:
              if (resp.body.error === "not-unique") {
                this.setState({submitting: false})
                signalFinished({isUsernameUnique: false})
              } else {
                throw new Error("Error on direct signup for user with 422.")
              }
              break
            default:
              throw new Error("Error on signup for user " + (state.email || state.parent_email) + ": (" + resp.statusCode + ") " + resp.statusText)
          }
          signalFinished() // Indicates no new state to communicate to the callback
        } else {
          throw new Error("Error on signup for user " + (state.email || state.parent_email))
        }
      })
    })
  },
  componentWillMount() {
    const subdomain = this.props.params.subdomain
    if (subdomain) {
      client.getSponsorForSignup(subdomain, (err, resp) => {
        if (resp.status === 200 && resp.body) {
          const {Full_Logo__c, Website_Logo_Size__c, Platform_Black_Logo__c, Optional_Direct_Signup_Fields__c, Turn_Off_Google_SSO__c} = resp.body

          this.setState({
            collectState: Optional_Direct_Signup_Fields__c.includes("state"),
            collectPhone: Optional_Direct_Signup_Fields__c.includes("phone-number"),
            blackLogo: Platform_Black_Logo__c,
            fullLogo: Full_Logo__c,
            logoSize: Website_Logo_Size__c,
            turnOffGoogleSSO: Turn_Off_Google_SSO__c
          })
        } else if (err) {
          throw new Error("Unknown error on get sponsor data: " + err)
        } else {
          throw new Error("Error on get sponsor data")
        }
      })
    }
  },
  render() {
    const {email, username, password, submit, collectState, collectPhone} = this.state
    const {
      params: {subdomain = "", b64_data: b64Data}
    } = this.props

    return (
      <DocumentTitle title="Sign Up Free: Banzai">
        <shared.Main>
          <shared.Panel className={cx("direct-panel", collectState && collectPhone && "wide")} {...this.state}>
            {shared.showView(machine, this)}
            <shared.HiddenLoginForm username={email || username} password={password} submit={submit} b64Data={b64Data} />
            <div>
              <TextButton2 className="already" onClick={bizShared.routeChange.bind(null, `login?signup=direct&subdomain=${subdomain}`)}>
                I already have an account.
              </TextButton2>
            </div>
          </shared.Panel>
          <shared.Footer subdomain={subdomain} />
        </shared.Main>
      </DocumentTitle>
    )
  }
})
