import "runtime"

import {createElement} from "react"
import ReactDOM from "react-dom"
import StudentSignup from "auth/component/student/signup"
import TeacherSignup from "auth/component/teacher/signup"
import SelectSchool from "auth/component/teacher/signup/school"
import DirectSignup from "auth/component/direct"
import Signup from "auth/component/signup"
import Login from "auth/component/login"
import uniloc from "uniloc"
import whatInput from "what-input"

import "../css/base.css"

const components = {
  signup: Signup,
  studentSignup: StudentSignup,
  teacherSignup: TeacherSignup,
  selectSchool: SelectSchool,
  directSignup: DirectSignup,
  login: Login
}

const router = uniloc({
  directSignup: "GET /direct/signup",
  studentSignup: "GET /student/signup",
  teacherSignup: "GET /teacher/signup",
  selectSchool: "GET /teacher/select/school",
  signup: "GET /signup",
  login: "GET /login"
})

const navigated = () => {
  const routeId = window.location.hash.replace(/^#\/?|\/$/g, "")
  const params = router.lookup(routeId).options
  ReactDOM.render(
    createElement(
      components[router.lookup(routeId).name || "login"],
      {params} // as props
    ),
    document.getElementById("app")
  )
}

window.addEventListener("hashchange", navigated, false)

window.onLoad = navigated()
