const trimString = string => string.replace(/\s+/g, " ").trim()

const splitFullName = fullName => {
  if (fullName) {
    const name = trimString(fullName).split(" ")
    const newName = []
    if (name.length > 2) {
      newName.push(`${name[0]} ${name[1]}`)
      newName.push(name.slice(2, name.length).join(" "))
      return newName
    } else {
      if (!name[1]) {
        name.push(null)
      }
      return name
    }
  } else {
    return [null, null];
  }
} // => ["Johnny Ives", "Depp"]

export const getFirstName = fullName => splitFullName(fullName)[0]
 
export const getLastName = fullName => splitFullName(fullName)[1]
