const isDayFinished = day => day && day != "day"

const isMonthFinished = month => month && month != "month"

const isYearFinished = year => year && year != "year"

const isDateFinished = ({day, month, year}) => isDayFinished(day) && isMonthFinished(month) && isYearFinished(year)

export const validateDatePicker = date => {
  const errors = []
  if (!isDateFinished(date)) {
 errors.push("date-not-finished")
}
  return errors
}

export const hasDateErrors = errors => errors.length > 0

export const getAge = dateString => {
  const today = new Date()
  const birthDate = new Date(dateString)
  let age = today.getFullYear() - birthDate.getFullYear()
  const m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age -= 1
  }
  return age;
}
