import React from "react"
import createReactClass from "create-react-class"
import cx from "classnames"
import DocumentTitle from "react-document-title"
import Body from "./signup/body"
import * as shared from "auth/component/shared"
import WavyLineWhite from "../../../../images/wavy-line-white.svg?react"
import WavyLine from "../../../../images/wavy-line.svg?react"
import CoppaBadge from "../../../../images/shield-bordered-checkmark-white.svg?react"
import FerpaBadge from "../../../../images/badge-bordered-checkmark-white.svg?react"
import Checkmark from "../../../../images/circled-checkmark-white.svg?react"

const Header = () => (
  <div className={cx("bg-neutral-20 text-primary-1 pt-9 px-6 pb-6 md:bg-primary-1 md:text-neutral-20 md:pt-0 md2:px-0 md2:pb-0")}>
    <div className="grid gap-y-4 max-w-[520px] mx-auto text-left body-text-sm md:gap-y-6">
      <div className="overline-sm md2:text-sm">Yes, it's really free</div>
      <div className="md:hidden w-[60px] h-[10px]">
        <WavyLine />
      </div>
      <div className="hidden md:block w-[60px] h-[10px]">
        <WavyLineWhite />
      </div>
      <div className="title">Join over 100,000 teachers nationwide who already use Banzai.</div>
    </div>
  </div>
)

const Check = ({children}) => (
  <div className="flex items-center gap-x-4 text-lg font-vs-regular lg:text-xl">
    <div className="h-6 w-6">
      <Checkmark />
    </div>
    {children}
  </div>
)

const Badge = ({title, text, children}) => (
  <div className="flex flex-col items-center gap-y-2">
    <div className="h-8 w-8">{children}</div>
    <div className="text-xl font-vs-semibold">{title}</div>
    <div className="text-md leading-none lg:text-lg">{text}</div>
  </div>
)

const Reassurance = () => (
  <div className="grid gap-y-12">
    <Header />
    <div className="grid gap-y-4">
      <Check>No lesson prep needed</Check>
      <Check>Grading is automatic</Check>
      <Check>Courses for all ages</Check>
      <Check>Workbooks for in-class use</Check>
    </div>
    <div className="text-left text-md lg:text-lg">*Products also available in Spanish</div>
    <div className="grid grid-cols-3 gap-x-10 lg:gap-x-12">
      <Badge title="ADA" text="Accessible">
        <Checkmark />
      </Badge>
      <Badge title="COPPA" text="Compliant">
        <CoppaBadge />
      </Badge>
      <Badge title="FERPA" text="Compliant">
        <FerpaBadge />
      </Badge>
    </div>
  </div>
)

export default createReactClass({
  componentDidMount() {
    document.body.classList.add("teacher-signup")
  },
  render: function () {
    return (
      <DocumentTitle title="Sign Up Free: Banzai">
        <shared.Main className="md2:grid md2:grid-cols-[520px_368px] md2:gap-x-14 md2:px-6 lg:grid-cols-[560px_416px] lg:gap-x-20">
          <div>
            <div className="md2:hidden">
              <Header />
            </div>
            <shared.Panel className="teacher-signup">
              <Body />
            </shared.Panel>
            <shared.Footer />
          </div>
          <div className="hidden md2:block md2:-mt-[80px]">
            <Reassurance />
          </div>
        </shared.Main>
      </DocumentTitle>
    )
  }
})
