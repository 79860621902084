import React from "react"
import DocumentTitle from "react-document-title"
import * as shared from "auth/component/shared"
import * as bizShared from "auth/business/shared"

export default () => (
  <DocumentTitle title="Teacher or Student: Banzai Signup">
    <shared.Main>
      <shared.Panel className="teacher-student">
        <div className="grid gap-y-9">
          <div className="grid gap-y-4">
            <shared.Question>Are you a student or a teacher?</shared.Question>
            <shared.Explanation>If you are a student, make sure you have your class code ready.</shared.Explanation>
          </div>
          <div className="grid gap-y-6">
            <shared.Button onClick={() => bizShared.routeChange("student/signup")}>I am a student.</shared.Button>
            <shared.Button onClick={() => bizShared.routeChange("teacher/signup")}>I am a teacher.</shared.Button>
          </div>
          <div className="mt-12">
            I'm actually with a{" "}
            <a className="link-underline" href="https://banzai.org/sponsors">
              bank, credit union
            </a>
            , or{" "}
            <a className="link-underline" href="https://banzai.org/business">
              business and benefits provider.
            </a>
          </div>
        </div>
      </shared.Panel>
      <shared.Footer />
    </shared.Main>
  </DocumentTitle>
)
