import request from "superagent"

export const submit = (params, cb) => {
  return request
  .post("/teachers/signup")
  .type("form")
  .send(params)
  .end(cb)
}
export const searchSchools = (zip, cb) => {
  return request
  .get("/api/schools?zip=" + zip)
  .accept("json")
  .end(cb)
}
export const validateUniqueUsername = (username, cb) => {
  return request
  .get("/api/users/username/validate?username=" + encodeURIComponent(username))
  .accept("json")
  .end(cb)
}
